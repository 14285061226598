import React, { useEffect, useMemo } from "react";
import {
  Avatar,
  Button,
  Form,
  Input,
  InputNumber,
  Progress,
  Select,
  Space,
} from "antd";
import {
  SyncOutlined,
  PhoneOutlined,
  GlobalOutlined,
  AudioOutlined,
  LoadingOutlined,
  BellOutlined,
  PauseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { BsTelephoneOutbound, BsTelephoneX } from "react-icons/bs";
import styled from "styled-components";
import ForwardPhoneButton from "./ForwardPhoneButton";
import { useTranslations } from "next-intl";
import { useMutation } from "@tanstack/react-query";
import { usePhoneFeatures } from "@/providers/app/PhoneFeaturesProvider";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";
import { UnmountClosed } from "react-collapse";

const Colors = {
  error: "#F5222D",
  success: "#23D3D3",
};

interface IProps {
  phoneNumber?: string;
}

export function PopupBeforeCallCollapse({ phoneNumber }: IProps) {
  const t = useTranslations("call");

  const [form] = Form.useForm();
  const { checklist, toggleReadyToReceiveCall, readyToReceiveCall } =
    usePhoneFeatures();
  const { callNumber } = usePhoneFeatures();
  const { PHONE_IFRAME_SYNC_SPEED } = useEnvironment();
  const { mutateAsync: callWrapped, isPending: isCalling } = useMutation({
    mutationFn: (phone_number: string) => {
      callNumber(phone_number);
      return new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  const { isPending: fakeLoadingTimer, mutate } = useMutation({
    mutationFn: async () => {
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED + 3000)
      );
      return true;
    },
  });
  const progress = useMemo(() => {
    return (
      (Object.values(checklist).filter((e) => e.ready).length /
        Object.values(checklist).length) *
      100
    );
  }, [checklist]);

  const onFormFinish = (values) => {
    const { phone_number } = values;
    callWrapped(phone_number);
  };

  useEffect(() => {
    mutate();
  }, [mutate]);
  useEffect(() => {
    form.setFieldValue("phone_number", phoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  return (
    <Space className="p-2 w-100" direction="vertical">
      {/*<div className="d-flex justify-content-between">*/}
      {/*  <span>{t("incoming_phone_number")}</span>*/}
      {/*  <Select className="col-6" size="small" placeholder="Số chung" />*/}
      {/*</div>*/}
      <div className="d-flex justify-content-between align-items-baseline">
        <div className="d-flex flex-row align-items-center">
          <div className="fw-semibold text-uppercase mr-2">
            {t("condition_call")}
          </div>

          {progress !== 100 && (
            <Progress
              status={
                fakeLoadingTimer
                  ? "active"
                  : progress === 100
                    ? "success"
                    : "normal"
              }
              percent={progress}
            />
          )}
          <UnmountClosed isOpened={progress === 100}>
            <Space className="ms-2">
              <Button
                icon={
                  <GlobalOutlined
                    style={{
                      color: checklist.register.ready
                        ? Colors.success
                        : Colors.error,
                    }}
                  />
                }
                type="dashed"
                style={{
                  borderColor: checklist.register.ready
                    ? Colors.success
                    : Colors.error,
                }}
              />
              <Button
                icon={
                  <AudioOutlined
                    style={{
                      color: checklist.register.ready
                        ? Colors.success
                        : Colors.error,
                    }}
                  />
                }
                type="dashed"
                style={{
                  borderColor: checklist.register.ready
                    ? Colors.success
                    : Colors.error,
                }}
              />
              {/*<Button*/}
              {/*  icon={<BsTelephoneOutbound style={{ color: "#F5222D" }} />}*/}
              {/*  style={{ borderColor: "#F5222D" }}*/}
              {/*  type="dashed"*/}
              {/*/>*/}
            </Space>
          </UnmountClosed>
        </div>
        {fakeLoadingTimer && (
          <Button icon={<SyncOutlined />} loading={fakeLoadingTimer} />
        )}
      </div>

      <Form onFinish={onFormFinish} form={form} layout="vertical">
        <div className="row gx-2 mt-3">
          <div className="col-6">
            <Form.Item
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: t("phone_number_required"),
                },
              ]}>
              <Input placeholder={t("phone_number_placeholder")} />
            </Form.Item>
          </div>
          <div className="col-6">
            <Button
              type="primary"
              htmlType="submit"
              loading={isCalling}
              className="w-100"
              icon={<PhoneOutlined />}>
              {t("call")}
            </Button>
          </div>
        </div>
      </Form>
    </Space>
  );
}

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
