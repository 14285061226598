"use client";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Rnd } from "react-rnd";
import { theme } from "antd";
import { ProCard } from "@ant-design/pro-components";
import styled from "styled-components";
import useDisclosure from "@/hooks/shared/useDisclosure";
import FadeIn from "@/components/shared/FadeIn";
import PhoneWindow from "./PhoneFeatures/components/PhoneWindow";
import { PhoneFeaturesProvider } from "@/providers/app/PhoneFeaturesProvider";

interface FloatingWindowProviderProps {
  children: React.ReactNode;
}

interface FloatingWindowProviderState {
  openWindow: (
    content: React.ReactNode,
    { x, y }: { x: number; y: number }
  ) => void;
  openPhoneWindow: () => void;
  close: () => void;
  isOpen: boolean;
}

export const FloatingWindowProviderContext = createContext<
  FloatingWindowProviderState | undefined
>(undefined);

const Styled = styled.div`
  .ant-pro-card {
    box-shadow:
      rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    /* border-radius: 18px; */
    /* background-color: transparent; */
  }
`;

const width = 320;
const height = 520;
const marginScreen = 24;

export const FloatingWindowProvider = ({
  children,
}: FloatingWindowProviderProps) => {
  const { isOpen, open, close } = useDisclosure(false);

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const [dimensions, setDimensions] = useState({
    width,
    height,
    x:
      typeof window !== "undefined"
        ? window.innerWidth - width - marginScreen
        : 0,
    y:
      typeof window !== "undefined"
        ? window.innerHeight - height - marginScreen * 2
        : 0,
  });
  const [contentWindow, setContentWindow] = useState<React.ReactNode | null>();

  const openWindow = (
    content: React.ReactNode,
    position?: { x: number; y: number }
  ) => {
    const { x, y } = position || {
      x: window.innerWidth - width - marginScreen,
      y: window.innerHeight - height - marginScreen * 2,
    };
    close();
    setContentWindow(content);
    setDimensions((prev) => ({ ...prev, x, y }));
    open();
  };

  const PreloadPhoneWindow = useMemo(() => {
    return (
      <PhoneFeaturesProvider>
        <PhoneWindow />
      </PhoneFeaturesProvider>
    );
  }, []);

  const openPhoneWindow = useCallback(() => {
    openWindow(PreloadPhoneWindow);
  }, [openWindow]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width,
        height,
        x: window.innerWidth - width - marginScreen,
        y: window.innerHeight - height - marginScreen * 2,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <FloatingWindowProviderContext.Provider
      value={{ openWindow, openPhoneWindow, close, isOpen, setDimensions }}>
      {children}
      {isOpen && contentWindow && (
        <Rnd
          size={{ width: dimensions.width, height: dimensions.height }}
          position={{ x: dimensions.x, y: dimensions.y }}
          onDragStop={(e, d) => {
            setDimensions((prev) => ({ ...prev, x: d.x, y: d.y }));
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            setDimensions({
              width: ref.style.width,
              height: ref.style.height,
              ...position,
            });
          }}
          bounds="window"
          style={{
            zIndex: 999,
            backgroundColor: "transparent",
          }}>
          <FadeIn delay={100}>
            <Styled>{contentWindow}</Styled>
          </FadeIn>
        </Rnd>
      )}
    </FloatingWindowProviderContext.Provider>
  );
};

export function useFloatingWindow(): FloatingWindowProviderState {
  const context = useContext<any>(FloatingWindowProviderContext);

  if (!context) {
    throw Error(
      "useFloatingWindow must be used within a FloatingWindowProvider"
    );
  }
  return context;
}

const StyledProCard = styled(ProCard)<{ primaryColor?: string }>`
  /* overflow: hidden; */
  .ant-pro-card-header {
    background-color: ${(props) => props.primaryColor};
    padding: 10px 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-pro-card-title {
    color: #ffffff;
    overflow: hidden;
  }
`;
